import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "MRatioAtmr",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      property: {
        listElement: {
          atmr: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        animation: {
          addRatioAtmr: {
            isLoading: false,
          },
          editRatioAtmr: {
            isLoading: false,
          },
        },
        modal: {
          showModalEditATMR: false,
          label: "",
          showModalAddATMR: false,
          showModalDetailATMR: false,
        },
        filterDto: {
          percentWeight: "",
        },
      },
      dataForm: {
        add: {
          chartOfAccountId: "",
          coaDescription: "",
          percentWeight: 0,
          atmrId: "",
        },
        edit: {
          chartOfAccountId: "",
          coaDescription: "",
          percentWeight: 0,
          atmrId: "",
        },
        detail: {
          chartOfAccountId: "",
          coaDescription: "",
          percentWeight: 0,
          atmrId: "",
          createdByUserCode: "",
          createdByUserName: "",
          createdDate: "",
          updatedDate: "",
          updatedByUserCode: "",
          updatedByUserName: "",
          authorizedDate: "",
          authorizedByUserCode: "",
          authorizedByUserName: "",
        },
      },
      options: {
        CoaAtmr: [],
      },
      table: {
        data: {
          dataAtmr: [],
        },
      },
    };
  },
  methods: {
    getReferenceChartOfAccount() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          params: {
            description: "",
            page: 0,
          },
          url: "chart-of-account",
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.content.map((index) => {
              const text = `${index.chartOfAccountCode}-${index.description}`;
              const value = index.chartOfAccountId;
              const deskripsi = index.description;
              this.options.CoaAtmr.push({ text, value, deskripsi });
            });
          }
        });
    },
    changeChartOfAccountAdd() {
      this.dataForm.add.coaDescription = "";
      this.options.CoaAtmr.map((index) => {
        if (index.value === this.dataForm.add.chartOfAccountId) {
          this.dataForm.add.coaDescription = index.deskripsi;
        }
      });
    },
    changeChartOfAccountEdit() {
      this.dataForm.edit.coaDescription = "";
      this.options.CoaAtmr.map((index) => {
        if (index.value === this.dataForm.edit.chartOfAccountId) {
          this.dataForm.edit.coaDescription = index.deskripsi;
        }
      });
    },
    async showModalDetailRatioAtmr(props) {
      this.property.modal.showModalDetailATMR = true;
      this.property.modal.label = "FORM DETAIL RATIO ATMR";
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl: "ratio-atmr/" + props.row.atmrId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.detail.chartOfAccountId =
              resp.data.data.chartOfAccount.chartOfAccountId;
            this.dataForm.detail.percentWeight = resp.data.data.percentWeight;
            this.dataForm.detail.atmrId = resp.data.data.atmrId;
            this.dataForm.detail.coaDescription =
              resp.data.data.chartOfAccount.description;
            this.dataForm.detail.createdByUserCode =
              resp.data.data.createdByUserCode;
            this.dataForm.detail.createdByUserName =
              resp.data.data.createdByUserName;
            this.dataForm.detail.createdDate = resp.data.data.createdDate;
            this.dataForm.detail.updatedDate = resp.data.data.updatedDate;
            this.dataForm.detail.updatedByUserCode =
              resp.data.data.updatedByUserCode;
            this.dataForm.detail.updatedByUserName =
              resp.data.data.updatedByUserName;
            this.dataForm.detail.authorizedDate = resp.data.data.authorizedDate;
            this.dataForm.detail.authorizedByUserCode =
              resp.data.data.authorizedByUserCode;
            this.dataForm.detail.authorizedByUserName =
              resp.data.data.authorizedByUserName;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
    async showModalEditRatioAtmr(props) {
      this.property.modal.showModalEditATMR = true;
      this.property.modal.label = "FORM EDIT RATIO ATMR";
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl: "ratio-atmr/" + props.row.atmrId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.edit.chartOfAccountId =
              resp.data.data.chartOfAccount.chartOfAccountId;
            this.dataForm.edit.percentWeight = resp.data.data.percentWeight;
            this.dataForm.edit.atmrId = resp.data.data.atmrId;
            this.dataForm.edit.coaDescription =
              resp.data.data.chartOfAccount.description;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
    async editRatioAtmr(props) {
      const payload = {
        chartOfAccountId: this.dataForm.edit.chartOfAccountId,
        percentWeight: this.dataForm.edit.percentWeight,
        coaDescription: this.dataForm.edit.coaDescription,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.editRatioAtmr.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "loan",
                reqUrl: "ratio-atmr/" + this.dataForm.edit.atmrId,
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.dialog.alert({
                    title: "Success !",
                    message: "Berhasil mengubah data",
                    confirmText: "Ok",
                    type: "is-success",
                    onConfirm: () => {
                      this.property.listElement.atmr.currentPage = 1;
                      this.property.listElement.atmr.rows = 0;
                      this.getRatioAtmr();
                    },
                  });
                  this.closeModalEditRatioATMR();
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.editRatioAtmr.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },

    async deleteRatioAtmr(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "loan",
                reqUrl: "ratio-atmr/" + props.row.atmrId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.atmr.currentPage = 1;
                  this.property.listElement.atmr.rows = 0;
                  this.getRatioAtmr();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async addRatioAtmr() {
      const payload = {
        chartOfAccountId: this.dataForm.add.chartOfAccountId,
        percentWeight: this.dataForm.add.percentWeight,
        coaDescription: this.dataForm.add.coaDescription,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.addRatioAtmr.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "loan",
                reqUrl: "ratio-atmr",
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.dialog.alert({
                    title: "Success !",
                    message: "Berhasil menyimpan data",
                    confirmText: "Ok",
                    type: "is-success",
                    onConfirm: () => this.getRatioAtmr(),
                  });
                  this.closeModalAddRatioATMR();
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.addRatioAtmr.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },

    handleErrorListRatioAtmr(error) {
      console.log(error.response);
      this.table.data.dataAtmr = [];
      this.property.listElement.atmr.rows = 0;
      this.property.listElement.atmr.currentPage = 1;
      this.property.listElement.atmr.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async getRatioAtmr() {
      this.table.data.dataAtmr = [];
      this.property.listElement.atmr.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "ratio-atmr",
          payload: {
            percentWeight: "",
            page: 0,
            size: this.property.listElement.atmr.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.dataAtmr = resp.data.data.content;
            this.property.listElement.atmr.rows = resp.data.data.totalElements;
            this.property.listElement.atmr.currentPage = 1;
          } else {
            this.failedGetDataRatioAtmr(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorListRatioAtmr(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.atmr.downloading = false;
        }, timeout);
      }
    },

    async searchByPercentWeightAtmr(event) {
      this.table.data.dataAtmr = [];
      this.property.listElement.atmr.downloading = true;
      const timeout = 1000;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "ratio-atmr",
          payload: {
            percentWeight: event === 0 ? "" : event,
            page: 0,
            size: this.property.listElement.atmr.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.dataAtmr = resp.data.data.content;
            this.property.listElement.atmr.rows = resp.data.data.totalElements;
            this.property.listElement.atmr.currentPage = 1;
            this.property.listElement.atmr.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetDataRatioAtmr(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorListRatioAtmr(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.atmr.downloading = false;
        }, timeout);
      }
    },

    closeModalAddRatioATMR() {
      this.property.modal.showModalAddATMR = false;
      this.property.animation.addRatioAtmr.isLoading = false;
      this.dataForm.add.atmrId = "";
      this.dataForm.add.chartOfAccountId = "";
      this.dataForm.add.coaDescription = "";
      this.dataForm.add.percentWeight = "";
    },
    closeModalEditRatioATMR() {
      this.property.modal.showModalEditATMR = false;
      this.property.animation.editRatioAtmr.isLoading = false;
      this.dataForm.edit.atmrId = "";
      this.dataForm.edit.chartOfAccountId = "";
      this.dataForm.edit.coaDescription = "";
      this.dataForm.edit.percentWeight = "";
    },
    closeModalDetailRatioATMR() {
      this.property.modal.showModalDetailATMR = false;
      this.dataForm.detail.atmrId = "";
      this.dataForm.detail.chartOfAccountId = "";
      this.dataForm.detail.coaDescription = "";
      this.dataForm.detail.percentWeight = "";
    },
    showModalAddRatioATMR() {
      this.property.modal.showModalAddATMR = true;
      this.property.modal.label = "FORM ADD SETUP ATMR";
    },

    failedGetDataRatioAtmr(resp) {
      this.table.data.dataAtmr = [];
      this.property.listElement.atmr.rows = 0;
      this.property.listElement.atmr.message = resp.data.message;
    },
    async changePaginationDataRatioAtmr(event) {
      this.table.data.dataAtmr = [];
      this.property.listElement.atmr.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "ratio-atmr",
          payload: {
            page: event - 1,
            size: this.property.listElement.atmr.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.dataAtmr = resp.data.data.content;
            this.property.listElement.atmr.rows = resp.data.data.totalElements;
          } else {
            this.failedGetDataRatioAtmr(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorListRatioAtmr(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.atmr.downloading = false;
        }, timeout);
      }
    },

    resetFormRatioAtmr(type) {
      switch (type) {
        case "ADD":
          this.dataForm.add.chartOfAccountId = "";
          this.dataForm.add.coaDescription = "";
          this.dataForm.add.percentWeight = 0;
          break;

        case "EDIT":
          this.dataForm.edit.chartOfAccountId = "";
          this.dataForm.edit.coaDescription = "";
          this.dataForm.edit.percentWeight = 0;

        default:
          return;
          break;
      }
    },
  },
  mounted() {
    this.getRatioAtmr();
    this.getReferenceChartOfAccount();
  },
};
